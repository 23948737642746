angular.module('MyHippoProducer.Controllers').directive('documentsSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/documents/documents.html',
        controller: function ($scope, PolicyService) {
            $scope.documents = PolicyService.policyInfo.aggregate_documents;
            $scope.formatDate = (date) => moment(date).format('MM/DD/YYYY HH:mm:ss');
            $scope.documentNames = {
                quote: 'Quote Packet',
                eoi: 'Evidence of Insurance with Invoice',
                eoi_only: 'Evidence Of Insurance',
                escrow_invoice: 'Escrow Invoice',
                policy_invoice: 'Policy Invoice',
                policy: 'Policy Documents',
                endorsement: 'Endorsement Documents',
                cancellation_notice: 'Cancellation Notice',
                billing: 'Billing',
                revert_cancel: 'Reinstate Notice',
                valuation: 'Verisk Valuation',
                refund: 'Notice of Premium Refund',
                refund_check: 'Refund Check',
                inspection_report: 'Inspection Report',
                non_renew: 'Non Renewal Letter',
                renewal_offer: 'Renewal Offer',
                terms: 'Terms',
                note_upload: 'Note upload',
                notice_of_application_declination: 'Notice of Declination',
                expiration_notice: 'Expiration Notice',
                invoice: 'Invoice',
                cancellation_assist: 'Cancellation Assist',
                declaration: 'Declaration',
                check: 'Check',
                cancellation_notice_wind: 'Wind Cancellation Notice',
                declaration_slacal: 'Declaration SlaCal',
                cancellation_slacal: 'Cancellation SlaCal',
                notice_of_conditional_renewal: 'Notice Of Conditional Renewal',
                watercraft_liability_bodily_injury_waiver: 'Watercraft Liability - Bodily Injury Coverage Waiver',
            };
        }
    };
});
